import React, { useEffect, useRef } from "react";
import { WiredCard } from "wired-elements-react";
import rough from "roughjs/bin/rough";

import "./Calendar.css";

const Calendar = () => {
  const svgRef = useRef(null);

  useEffect(() => {
    const birthday = new Date(1990, 6, 31, 0, 0, 0, 0);
    const today = new Date();

    const ageTime = Math.abs(today.getTime() - birthday.getTime());
    const ageWeeks = Math.ceil(ageTime / (1000 * 60 * 60 * 24 * 7));

    let count = 0;
    // @ts-ignore
    const roughSvg = rough.svg(svgRef.current);
    for (let i = 0; i < 100; i++) {
      for (let j = 0; j < 52; j++) {
        const options = {
          roughness: 0,
          ...(count <= ageWeeks && {
            fill: "rgba(108,122,137,1)",
            fillStyle: "solid",
          }),
        };

        // @ts-ignore
        svgRef.current?.appendChild(
          roughSvg.rectangle(j * 16, i * 16, 12, 12, options)
        );
        count++;
      }
    }
  }, []);

  return (
    <main>
      {/*@ts-ignore */}
      <WiredCard elevation={3}>
        <h1>Weeks of My Life</h1>
        <section className="Calendar__svg-container">
          <svg ref={svgRef} viewBox="0 0 830 1600"/>
        </section>
      </WiredCard>
    </main>
  );
};

export default Calendar;
