import React from "react";
import "./App.css";
import Calendar from "./calendar/Calendar";

function App() {
  return (
    <div className="App">
      <div className="App__calendar">
        <Calendar />
      </div>
    </div>
  );
}

export default App;
